<template>
  <div class="position-relative cursor-pointer drop-box-container pt-5"
       :class="{ 'dragging-over': draggingOver,
                 'inline-drop-text': inlineDropText }"
       @click="launchFileSelector">
    <div class="position-absolute top-0 w-100 h-100 opacity-0"
         style="z-index: 10;"
         @dragenter="dragEnterHandler"
         @dragleave="dragLeaveHandler"
         @dragover="dragOverHandler"
         @drop="dropHandlerRepeater">

    </div>
    <slot name="default" :uploading="uploading">
      <div class="text-center position-relative drop-box">
        <fluency-loader v-if="uploading">Uploading {{uploadPercentage || ''}}%</fluency-loader>
        <div v-else class="down-arrow">
          <fluency-icon type="arrowDown" />
        </div>
        <h6>
          <span v-html="dropZoneText"></span>
        </h6>
      </div>
    </slot>
    <input ref="hiddenFileSelector"
           type="file"
           multiple
           class="invisible"
           style="width:1px;height:1px;"
           @change="selectHandler" />
  </div>
</template>

<script>
import { debounce as _debounce } from 'lodash'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'

export default {
  name: 'FluencyFileDrop',
  components: { FluencyLoader },
  props: {
    handler: {
      type: Function,
      required: true
    },
    uploading: {
      type: Boolean,
      default: false
    },
    uploadPercentage: {
      type: [Number, String],
      default: 0
    },
    dropZoneText: {
      type: String,
      default: 'Drag & drop asset here,<br/> or click to browse'
    },
    allowDirectories: {
      type: Boolean,
      default: false
    },
    maxNumFiles: {
      type: Number,
      default: 20
    },
    inlineDropText: {
      type: Boolean,
      default: false
    },
    mbSizeLimit: {
      type: Number
    }
  },
  data () {
    return {
      draggingOver: false,
      dropHandlingInProgress: false,
      maxNumFilesExceededMsg: `You are limited to uploading ${this.maxNumFiles} at a time.`
    }
  },
  methods: {
    launchFileSelector () {
      this.$refs.hiddenFileSelector.click()
    },
    dragEnterHandler (ev) {
      ev.preventDefault()
      this.draggingOver = true
    },
    dragLeaveHandler: _debounce(function (ev) {
      ev.preventDefault()
      this.draggingOver = false
    }, 100),
    dragOverHandler (ev) {
      ev.preventDefault()
    },
    dropHandlerRepeater (ev) {
      if (!this.dropHandlingInProgress && !this.dropEvent) { // don't activate the repeater if you have a drop event defined because that will fire from the watcher -SNG
        this.dropHandler(ev)
      }
    },
    checkFileSize (file) {
      const mb = file.size / (1024 * 1024)
      if (mb > this.mbSizeLimit) {
        this.$toast(`File Exceeds The Limit Of ${this.mbSizeLimit}mb.`, 'danger')
        return false
      }
      return true
    },
    selectHandler (ev) {
      if (ev.target?.files) {
        for (let i = 0; i < ev.target.files.length && i < this.maxNumFiles; i++) {
          if (this.mbSizeLimit) {
            const pass = this.checkFileSize(ev.target?.files?.[i])
            if (!pass) {
              return
            }
          }
          this.handler(ev.target?.files?.[i], ev.target.files.length - i, '', ev.target.files.length)
        }

        if (ev.target.files.length > this.maxNumFiles) {
          this.$alert(this.maxNumFilesExceededMsg)
        }
      }
    },
    dropHandler (ev) {
      this.draggingOver = false
      ev.preventDefault()

      // Prevent default behavior (Prevent file from being opened)
      this.dropHandlingInProgress = true
      if (this.mbSizeLimit) {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          const pass = this.checkFileSize(ev.dataTransfer.files[i])
          if (!pass) {
            return
          }
        }
      }

      if (ev.dataTransfer.items) {
        const items = []
        for (let i = 0; i < ev.dataTransfer.items.length && i < this.maxNumFiles; i++) {
          if (ev.dataTransfer.items[i].kind === 'file') {
            items.push(ev.dataTransfer.items[i])
          }
        }

        if (ev.dataTransfer.files.length > this.maxNumFiles) {
          this.$alert(this.maxNumFilesExceededMsg)
        }

        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < items.length; i++) {
          if (this.allowDirectories) {
            const item = items[i].webkitGetAsEntry()
            this.processEntry(item, items.length - i, '', true, items.length)
          } else {
            this.handler(items[i].getAsFile(), items.length - i, '', items.length)
          }
        }
      }
      this.dropHandlingInProgress = false
    },
    processEntry (entry, i, path = '', root = false, length) {
      if (!entry) return
      if (entry.isFile) {
        entry.file((f) => {
          this.handler(f, i, `${path}${f.name}`, length)
        })
      } else if (this.allowDirectories && entry.isDirectory) {
        const reader = entry.createReader()
        reader.readEntries((subEntries) => {
          subEntries.forEach((subEntry) => {
            const newPath = root ? '' : `${path}${entry.name}/`
            this.processEntry(subEntry, i, newPath, false, length)
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.down-arrow {
  width: 50px;
  height: 50px;
  margin: 0 auto 1em auto;
  padding-top: 0.5em;
  font-size: 150%;
  border: 2px dotted $fluency-lightgray;
}

.drop-box * {
  transition: border-color 200ms linear,color 200ms linear;
}

.dragging-over {
  .drop-box * {
    color: var(--p-primary-color);
  }
  .down-arrow {
    border-color: var(--p-primary-color);
  }
}

.inline-drop-text {
  .drop-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .down-arrow {
    margin-left: 0!important;
    margin-right: 1rem;
    margin-bottom: 0!important;
  }
}
</style>
